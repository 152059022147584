<template>
  <el-dropdown @command="handleCommand" v-if="isLogin">
    <span class="el-dropdown-link">
      <div class="login div-avatar">
        <span class="nickname">{{ nickname }}</span>
        <el-avatar class="ml-16" :size="40" :src="avatar" />
      </div>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="account">帐号信息</el-dropdown-item>
      <el-dropdown-item command="logout">退出登录</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
  <div v-else>
    <span class="btn-text-l" @click="handleLogin">登录</span>
    <span class="remark-text ml-8 mr-8">|</span>
    <span class="btn-text-l" @click="handleRegister">注册 </span>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  props: {
    light: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("oidc", { isLogin: "oidcIsAuthenticated" }),
    ...mapGetters(["nickname", "avatar", "userInfo"])
  },
  methods: {
    ...mapActions("oidc", {
      signIn: "authenticateOidc",
      signOut: "signOutOidc"
    }),
    async handleCommand(command) {
      if (command === "logout") {
        this.$confirm("您确定要注销当前登录用户吗？", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(_ => {
            this.signOut(); //.then(_ => this.$router.push('/')).catch(_ => this.$router.push('/'));
          })
          .catch(err => {
            console.error(err);
          });
      } else if (command === "account") {
        this.$router.push(`/account`);
      }
    },
    handleLogin() {
      this.signIn({ options: { extraQueryParams: { type: "login" } } });
    },
    handleRegister() {
      this.signIn({ options: { extraQueryParams: { type: "register" } } });
    }
  }
};
</script>

<style>
.nickname {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.div-avatar {
  display: flex;
  align-items: center;
  cursor: pointer;
}
/* .v-sheet--tile,.v-toolbar__content{
  height: 60px !important;
} */
</style>
