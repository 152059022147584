<template>
  <div class="logo-wrap">
    <img class="mylogo" src="@/assets/logo.png" alt @click="goHome" />
    <!-- <span class="system-name">{{ "基础数据管理平台" }}</span> -->
  </div>
</template>
<script>
export default {
  methods: {
    goHome() {
      // this.$router.push("/");
    }
  }
};
</script>
<style lang="scss" scoped>
.logo-wrap {
  display: flex;
  align-items: center;
}
.mylogo {
  height: 29px;
  cursor: pointer;
}
.system-name {
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 33px;
}
</style>
