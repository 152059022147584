export const schoolMenu = [
  { title: "学校信息", icon: "iconjichushuju-shouye", path: "/DCDataCentre" },
  // { title: "学校信息", icon: "iconjichushuju-xuexiao", path: "/school/info" },
  {
    title: "组织架构",
    icon: "iconjichushuju-zuzhijiagou",
    path: "/DCDataCentre/organization"
  },
  {
    title: "教师管理",
    icon: "iconjichushuju-jiaoshirenshu",
    path: "/DCDataCentre/leftTeacher"
  },
  {
    title: "学生管理",
    icon: "iconjichushuju-xueshengrenshu",
    path: "/DCDataCentre/leftStudent"
  }
  // { title: "教师管理", icon: "teacher", path: "/school/teacher/index" },
  // { title: "学生管理", icon: "student", path: "/school/student/index" }
];
export const enterpriseMenu = [
  { title: "首页", icon: "iconjichushuju-shouye", path: "/enterprise" }
  // {
  //   title: "企业信息",
  //   icon: "iconlianxiqiyeyuanxiao",
  //   path: "/enterprise/info"
  // },
  // {
  //   title: "组织架构",
  //   icon: "iconjichushuju-zuzhijiagou",
  //   path: "/enterprise/organization"
  // }
];
export const customMenu = [
  { title: "首页", icon: "iconjichushuju-shouye", path: "/custom" }
];
