<template>
  <div class="siderbar">
    <el-menu
      :default-active="activeMenu"
      class="el-menu-vertical"
      @select="select"
    >
      <template v-for="(route, index) in routes">
        <el-menu-item :index="route.path" :key="index">
          <i :class="`iconfont ${route.icon}`" />
          <span class="title" slot="title">
            {{ route.title }}
          </span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { schoolMenu, enterpriseMenu, customMenu } from "@/utils/menu";
import store from "@/store";
export default {
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    routes() {
      console.log(
        JSON.parse(sessionStorage.getItem("CurrentThreePartInfo")).type
      );
      switch (JSON.parse(sessionStorage.getItem("CurrentThreePartInfo")).type) {
        case "school":
          return schoolMenu;
        case "enterprise":
          return enterpriseMenu;
        default:
          return customMenu;
        // case "DCDataCentre":
        //   return schoolMenu;
        // case "enterprise":
        //   return enterpriseMenu;
        // default:
        //   return schoolMenu;
      }
    },
  },
  methods: {
    select(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.siderbar {
  width: 240px;
  height: 100%;
  overflow-y: auto;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 16px 0;
  .title {
    margin-left: 8px;
  }
}
</style>
