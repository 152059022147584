<template>
  <div class="app-wrapper">
    <div class="layout-content">
      <div class=""><siderbar></siderbar></div>
      <div class="main-container"><app-main></app-main></div>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar";
import Siderbar from "./components/Siderbar";
import AppMain from "./components/AppMain";
export default {
  components: {
    Navbar,
    Siderbar,
    AppMain,
  },
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  // min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #f5f5f5;

  .layout-content {
    flex: 1;
    display: flex;
    margin: 24px 24px 24px 24px;
    .main-container {
      position: relative;
      width: 100%;
      margin-left: 16px;
      background: #fff;
      overflow: hidden;
      border-radius: 8px;
      box-sizing: border-box;
    }
  }
}
</style>
