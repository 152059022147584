<template>
  <div class="navbar">
    <logo></logo>
    <account></account>
  </div>
</template>

<script>
import Logo from "./Logo";
import Account from "./Account";
export default {
  components: {
    Logo,
    Account
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  height: 68px;
  width: 100%;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(51, 51, 51, 0.06);
}
</style>
